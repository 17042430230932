import { Helmet } from 'react-helmet-async';
import { Container } from './Container';
import { AppError } from '../errors/AppError';

export function ErrorFallback({ error }: { error: Error }) {
  // TODO send error strack trace to the server
  console.error(error);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container>
        <div className="alert alert-danger m-2">
          {error instanceof AppError ? error.message : 'Something went wrong.'}
        </div>
      </Container>
    </>
  );
}
