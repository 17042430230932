import axios from 'axios';
import { getIdToken } from '../../storage/idTokenStorage';

export function createAuthorizedClient() {
  const client = axios.create();
  client.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  client.interceptors.request.use(function (config: any) {
    const idToken = getIdToken();
    if (!idToken) {
      throw new HTTPClientError(
        `Http client error: could not send request to API without id_token`
      );
    }
    config.headers.Authorization = idToken;

    return config;
  });
  return client;
}

export class HTTPClientError extends Error {}
