export enum PageType {
  HOMEPAGE,
  RESTRICTED,
  DOCUMENTATION,
  GENERAL,
}

export type RoutesType = Record<
  string,
  {
    component: () => Promise<() => JSX.Element>;
    pageType: PageType;
  }
>;

// Dynamic imports here are useful not only because react doesn't load all the components,
// but also because route paths can be used for a sitemap generation.
export const routes: RoutesType = {
  '/': {
    component: () => import('./navigation/Home').then(({ Home }) => Home),
    pageType: PageType.HOMEPAGE,
  },
  '/models': {
    component: () =>
      import('./navigation/models/List').then(({ List }) => List),
    pageType: PageType.RESTRICTED,
  },
  '/models/:modelId': {
    component: () =>
      import('./navigation/models/Edit').then(({ Edit }) => Edit),
    pageType: PageType.RESTRICTED,
  },
  '/account': {
    component: () =>
      import('./navigation/AccountEdit').then(({ AccountEdit }) => AccountEdit),
    pageType: PageType.RESTRICTED,
  },
  '/contact-us': {
    component: () =>
      import('./navigation/ContactUs').then(({ ContactUs }) => ContactUs),
    pageType: PageType.GENERAL,
  },
  '/terms': {
    component: () => import('./navigation/Terms').then(({ Terms }) => Terms),
    pageType: PageType.GENERAL,
  },
  '/privacy': {
    component: () =>
      import('./navigation/Privacy').then(({ Privacy }) => Privacy),
    pageType: PageType.GENERAL,
  },
  '/pricing': {
    component: () =>
      import('./navigation/PricedPlans').then(({ PricedPlans }) => PricedPlans),
    pageType: PageType.GENERAL,
  },
  '/docs/model-requirements': {
    component: () =>
      import('./navigation/docs/ModelRequirements').then(
        ({ ModelRequirements }) => ModelRequirements
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/model-configuration': {
    component: () =>
      import('./navigation/docs/ModelConfiguration').then(
        ({ ModelConfiguration }) => ModelConfiguration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/visibility-and-product-pages': {
    component: () =>
      import('./navigation/docs/VisibilityAndProductPages').then(
        ({ VisibilityAndProductPages: VisibilityAndProductPages }) =>
          VisibilityAndProductPages
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/preset-configuration': {
    component: () =>
      import('./navigation/docs/PresetConfiguration').then(
        ({ PresetConfiguration }) => PresetConfiguration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/call-to-action-configuration': {
    component: () =>
      import('./navigation/docs/CallToActionConfiguration').then(
        ({ CallToActionConfiguration }) => CallToActionConfiguration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/display-options': {
    component: () =>
      import('./navigation/docs/DisplayOptions').then(
        ({ DisplayOptions }) => DisplayOptions
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/ar-configuration': {
    component: () =>
      import('./navigation/docs/ARConfiguration').then(
        ({ ARConfiguration }) => ARConfiguration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/wix-integration': {
    component: () =>
      import('./navigation/docs/WixIntegration').then(
        ({ WixIntegration }) => WixIntegration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/shopify-integration': {
    component: () =>
      import('./navigation/docs/ShopifyIntegration').then(
        ({ ShopifyIntegration }) => ShopifyIntegration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/docs/wordpress-integration': {
    component: () =>
      import('./navigation/docs/WordPressIntegration').then(
        ({ WordPressIntegration }) => WordPressIntegration
      ),
    pageType: PageType.DOCUMENTATION,
  },
  '/success-registration': {
    component: () =>
      import('./navigation/SuccessRegistration').then(
        ({ SuccessRegistration }) => SuccessRegistration
      ),
    pageType: PageType.RESTRICTED,
  },
  '/experience': {
    component: () =>
      import('./navigation/ExperienceWrapper').then(
        ({ ExperienceWrapper }) => ExperienceWrapper
      ),
    pageType: PageType.GENERAL,
  },
  '/analytics': {
    component: () =>
      import('./navigation/UserAnalytics').then(
        ({ UserAnalytics }) => UserAnalytics
      ),
    pageType: PageType.RESTRICTED,
  },
};
