import { TelephoneForward, Envelope, GeoAlt } from 'react-bootstrap-icons';
import { HtmlProps } from 'react-helmet-async';

export const FooterContact = (props: HtmlProps) => (
  <div
    className={
      'flex flex-col justify-between items-center gap-2 w-64 md:w-auto ' +
      props.className
    }
  >
    <div className="flex flex-col md:flex-row gap-3 w-full justify-between whitespace-nowrap">
      <div className="flex gap-3 justify-between items-center border-b">
        <a href="tel:+16472540954">+1 (647) 254-0954</a>
        <TelephoneForward className="w-4" />
      </div>
      <div className="flex gap-3 justify-between items-center border-b">
        <a href="mailto:info@wearitar.com">info@wearitar.com</a>
        <Envelope className="w-4" />
      </div>
    </div>
    <div className="flex gap-3 justify-between items-center lg:items-start border-b w-full lg:whitespace-nowrap">
      <a
        href="https://maps.app.goo.gl/UpnTVFLqvbzH4vDV9"
        target="_blank"
        className="w-fit"
      >
        202-120 Eglinton Ave. E., Toronto, ON M4P 1E2
      </a>
      <GeoAlt className="w-4" />
    </div>
  </div>
);
