import { Person, Speedometer2 } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import { HTMLProps } from 'react';
import { ReactSVG } from 'react-svg';
import { LogoutButton } from './LogoutButton';

export const userMenuLinks = [
  {
    label: 'Analytics',
    to: '/analytics',
    icon: <Speedometer2 className="h-5 w-5" />,
  },
  {
    label: 'Models',
    to: '/models',
    icon: (
      <ReactSVG
        src="/assets/images/3d-monitor-screen.svg"
        className="w-5"
        wrapper="span"
      />
    ),
  },
  {
    label: 'Account',
    to: '/account',
    icon: <Person className="h-5 w-5" />,
  },
];

export const UserSidebar = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <aside {...props}>
      <ul className="menu menu-sm gap-2 lg:menu-md w-full ps-0 py-0">
        {userMenuLinks.map((link) => (
          <li key={link.to}>
            <NavLink
              className={({ isActive }) =>
                isActive ? ' bg-primary text-white' : ''
              }
              to={link.to}
            >
              {link.icon}
              {link.label}
            </NavLink>
          </li>
        ))}
        <li></li>
        <li>
          <LogoutButton />
        </li>
      </ul>
    </aside>
  );
};
