import { createContext } from 'react';
import { ProductCategory } from '../models/ProductCategory';

export type UserData = {
  sub: string;
  name?: string;
  email?: string;
  phone?: string;
  given_name?: string;
  family_name?: string;
  company_name?: string;
  phone_number?: string;
  address?: string;
  is_subscription_verified?: boolean;
  is_payment_profile_created?: boolean;
  product_categories: ProductCategory[];
  'custom:company_name'?: string;
  'custom:country'?: string;
  'cognito:username'?: string;
  'cognito:groups': string[];
  'custom:company_type'?: string;
  'custom:employees_num'?: string;
  picture?: string;
};

export type UserContextType = {
  userData?: UserData | undefined;
  onLogout?: () => void;
  setUserData?: (userData: UserData) => void;
};

export const UserContext = createContext<UserContextType>({});
