import { AwsRum, AwsRumConfig } from 'aws-rum-web';

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL,
    endpoint: `https://dataplane.rum.${process.env.REACT_APP_AWS_RUM_REGION}.amazonaws.com`,
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID || '';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = process.env.REACT_APP_AWS_RUM_REGION || '';

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
