import { useEffect, useState } from 'react';
import { usePersistentState } from './hooks/usePersistentState';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext, UserData } from './contexts/UserContext';
import { Buffer } from 'buffer';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import {
  KEY as ID_TOKEN_STORAGE_KEY,
  removeIdToken,
} from './storage/idTokenStorage';
import { areAllRequiredUserAttributesFilled } from './utils/UserAttributes';
import './App.css';
import { useCanonical } from './hooks/useCanonical';
import { reloadUserData } from './utils/reloadUserData';
import { getSignInEndpoint } from './utils/getSignInEndpoint';
import { Router } from './Router';
import { ErrorFallback } from './components/ErrorFallback';
import TagManager from '@sooro-io/react-gtm-module';
import './aws-rum-web';
import Cookies from 'universal-cookie';
import PrivacyConsentForm from './components/Consent/PrivacyConsentForm';
import { ConsentFormProvider } from './components/Consent/ConsentFormContext';
import { convertConsent } from './components/Consent/ConsentUtils';

const COOKIE_NAME = 'user_consents';

function App() {
  const [idToken, setIdToken] = usePersistentState(ID_TOKEN_STORAGE_KEY);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  // Store token
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const searchParams = new URLSearchParams(hash);
      const idToken = searchParams.get('id_token');
      if (idToken) {
        setIdToken(idToken);
      }
    }
  }, [setIdToken]);

  // Handle user data from the id token and from data from the endpoint
  useEffect(() => {
    if (!idToken) {
      return;
    }
    const userData = idToken
      ? JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString())
      : {};

    reloadUserData(userData, setUserData).catch(() => {
      // Id token exists but it's not valid
      removeIdToken();
      window.location.replace(getSignInEndpoint('signup'));
    });
  }, [idToken]);

  // Handle redirect after login
  useEffect(() => {
    if (!idToken) {
      return;
    }
    (async () => {
      if (!userData || userData.is_subscription_verified === undefined) {
        return;
      }

      const notRedirectedPaths = [
        '/account',
        '/terms',
        '/privacy',
        '/contact-us',
        '/docs/*',
        '/success-registration',
      ];

      if (notRedirectedPaths.includes(location.pathname)) {
        return;
      }

      if (
        !areAllRequiredUserAttributesFilled(userData) ||
        (!userData.is_subscription_verified &&
          userData.is_payment_profile_created &&
          location.pathname !== '/pricing') ||
        location.pathname === '/account'
      ) {
        navigate('/account');
        return;
      }

      if (
        !userData.is_subscription_verified &&
        !userData.is_payment_profile_created
      ) {
        navigate('/pricing');
        return;
      }

      if (window.location.hash) {
        navigate('/analytics');
      }
    })();
  }, [userData, navigate]);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_ID) {
      const cookies = new Cookies();
      const consentCookie = cookies.get(COOKIE_NAME);

      const dataLayer = {
        consent: 'default',
        functionality_storage: 'denied',
        security_storage: 'denied',
        analytics_storage: 'denied',
        personalization_storage: 'denied',
        ad_user_data: 'denied',
        ad_storage: 'denied',
        ad_personalization: 'denied',
      };
      // Initialize GTM with the default consents
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
        dataLayer,
      };

      console.log(
        'Initializing GTM with args:',
        JSON.stringify(tagManagerArgs)
      );

      TagManager.initialize(tagManagerArgs);

      if (consentCookie) {
        TagManager.dataLayer({
          dataLayer: {
            consent: 'update',
            ...convertConsent(consentCookie),
          },
        });
      }
    }
  }, []);

  return (
    <>
      <ConsentFormProvider>
        <div className="absolute flex items-center justify-center min-h-screen bg-gray-100">
          <PrivacyConsentForm />
        </div>
        <HelmetProvider>
          <UserContext.Provider
            value={{
              userData,
              onLogout: () => {
                removeIdToken();
                setUserData(undefined);
                navigate('/');
              },
              setUserData,
            }}
          >
            <Helmet>
              <link rel="canonical" href={useCanonical()} />
            </Helmet>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Router />
            </ErrorBoundary>
          </UserContext.Provider>
        </HelmetProvider>
      </ConsentFormProvider>
    </>
  );
}

export default App;
