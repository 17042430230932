import { useEffect } from 'react';

export const useInlineScript = (
  scriptUri: string,
  props?: Partial<Element>
) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptUri;
    script.async = true;
    script.defer = true;
    if (props) {
      Object.entries(props).map(([propName, propValue]) =>
        script.setAttribute(propName, String(propValue))
      );
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
