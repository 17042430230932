import { HTMLProps } from 'react';

export const Loading = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
  <div
    className={
      'loading loading-infinity text-primary p-5 max-w-fit flex mx-auto ' +
      className
    }
    {...props}
  />
);
