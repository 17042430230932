import * as storage from './persistentTokenStorage';

export const KEY = 'id_token';

export function getIdToken(): string | null {
  return storage.getValue(KEY).toString();
}

export function setIdToken(value: string) {
  return storage.setValue(KEY, value);
}

export function removeIdToken() {
  return storage.remove(KEY);
}
