import React from 'react';

interface ConsentToggleProps {
  label: string;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  description?: string;
}

const ConsentToggle: React.FC<ConsentToggleProps> = ({
  label,
  name,
  checked,
  onChange,
  description,
}) => {
  return (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <span className="text-gray-700 font-semibold">{label}</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            className="sr-only peer"
          />
          <div
            className="w-11 h-6 bg-gray-200 rounded-full
              peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-600
              peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white
              after:content-[''] after:absolute after:left-[2px] after:top-0.5 after:bg-white after:border-gray-300 after:border
              after:rounded-full after:h-5 after:w-5 after:transition-all"
          ></div>
        </label>
      </div>
      {description && (
        <p className="text-gray-600 text-sm mt-1">{description}</p>
      )}
    </div>
  );
};

export default ConsentToggle;
