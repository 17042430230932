import { Container } from './Container';
import { Footer } from './Footer/Footer';
import { Navbar } from './Navbar';
import { UserSidebar } from './UserSidebar';

export const UserLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <Container>
        <Navbar />
        <div className="flex flex-row gap-2">
          <UserSidebar className="hidden lg:flex w-80" />
          <div className="w-full bg-gray-50 p-3 rounded">{children}</div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
