import React, { useState, useEffect, useContext } from 'react';
import ConsentToggle from './ConsentToggle';
import Cookies from 'universal-cookie';
import { ConsentFormContext } from './ConsentFormContext';
import { Consents, convertConsent } from './ConsentUtils';
import TagManager from '@sooro-io/react-gtm-module';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const COOKIE_NAME = 'user_consents';
const COOKIE_EXPIRATION_DAYS = 365;

const PrivacyConsentForm: React.FC = () => {
  const { showConsentForm, setShowConsentForm } =
    useContext(ConsentFormContext)!;

  const [consents, setConsents] = useState<Consents>({
    essential: true, // Essential cookies are always enabled
    basic_analytics: true,
    advanced_analytics: false,
    marketing: false,
  });

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const cookies = new Cookies();

  // Read consent from cookies when component mounts
  useEffect(() => {
    const consentCookie = cookies.get(COOKIE_NAME);
    if (consentCookie) {
      setConsents(consentCookie);
    } else {
      // If no consent yet, show the consent form
      setShowConsentForm(true);
    }
  }, [setShowConsentForm]);

  const handleAcceptAll = () => {
    const allConsents: Consents = {
      essential: true,
      basic_analytics: true,
      advanced_analytics: true,
      marketing: true,
    };

    saveConsents(allConsents);

    // Update GTM with the new consents
    updateGTMConsents(allConsents);

    // Close the form
    setShowConsentForm(false);
  };

  const handleManage = () => {
    setShowDetails(true);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Prevent toggling of essential cookies
    if (name === 'essential') return;

    setConsents((prevConsents) => ({ ...prevConsents, [name]: checked }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    saveConsents(consents);

    // Update GTM with the new consents
    updateGTMConsents(consents);

    // Close the form
    setShowConsentForm(false);
  };

  const saveConsents = (consentsToSave: Consents) => {
    cookies.set(COOKIE_NAME, consentsToSave, {
      path: '/',
      maxAge: COOKIE_EXPIRATION_DAYS * 24 * 60 * 60,
    });
  };

  const updateGTMConsents = (updatedConsents: Consents) => {
    TagManager.dataLayer({
      dataLayer: {
        consent: 'update',
        ...convertConsent(updatedConsents),
      },
    });
  };

  if (!showConsentForm) {
    return null;
  }

  return (
    <>
      {/* Overlay */}
      <div
        className="fixed inset-0 pointer-events-none"
        aria-hidden="true"
      ></div>

      {/* Consent Form */}
      <div
        className="fixed bottom-0 left-0 z-50 m-4 w-full max-w-sm"
        style={{ maxHeight: 'calc(100% - 2rem)' }} // Subtract margin to prevent overflow
      >
        {/* Consent Form Container */}
        <div
          className="m-4 w-full max-w-sm bg-white rounded-md shadow-md p-4 overflow-y-auto"
          style={{
            maxHeight: 'calc(100vh - 2rem)', // Adjust for margins
            pointerEvents: 'auto',
          }}
        >
          {!showDetails ? (
            // Initial view with Accept and Manage buttons
            <>
              <h2 className="mb-3 text-lg font-semibold text-gray-800">
                Privacy Consent
              </h2>
              <p className="font-light text-[#818198] text-sm leading-normal pb-6">
                We and our partners use cookies to give you the best optimized
                online experience, analyze our website traffic, and serve you
                with personalized ads. You can agree to the collection of all
                cookies by clicking Accept all and close or adjust your cookie
                settings by clicking Manage cookies. You also have the right to
                withdraw your consent or change your cookie preferences anytime
                by clicking the Manage cookies link in our website footer.
              </p>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleManage}
                  className="btn btn-sm text-capitalize"
                >
                  Manage cookies
                </button>
                <button
                  onClick={handleAcceptAll}
                  className="btn btn-sm btn-primary"
                >
                  Accept all and close
                </button>
              </div>
            </>
          ) : (
            // Detailed view with toggles
            <form onSubmit={handleSubmit}>
              <h2 className="mb-4 text-xl font-semibold text-gray-800">
                Privacy Preferences
              </h2>

              {/* Essential Cookies (No Toggle) */}
              <div className="mb-4">
                <span className="text-gray-700 font-semibold">
                  Essential Cookies
                </span>
                <p className="text-gray-600 text-sm">
                  These first-party cookies are necessary for the functioning
                  and security of our website and the services you require. You
                  cannot opt out of these cookies.
                </p>
              </div>

              {/* Consent Toggles */}
              <ConsentToggle
                label="Basic Analytical Cookies"
                name="basic_analytics"
                checked={consents.basic_analytics}
                onChange={handleToggle}
                description="These cookies enable us to measure the number of visitors and create aggregated usage statistics."
              />

              <ConsentToggle
                label="Advanced Analytical Cookies"
                name="advanced_analytics"
                checked={consents.advanced_analytics}
                onChange={handleToggle}
                description="These cookies help us understand how you interact with our website to improve user experience."
              />

              <ConsentToggle
                label="Marketing Cookies"
                name="marketing"
                checked={consents.marketing}
                onChange={handleToggle}
                description="These cookies allow our marketing partners to show you more relevant targeted ads."
              />

              <div className="flex justify-end space-x-2 mt-4">
                <button
                  type="button"
                  onClick={() => setShowDetails(false)}
                  className="btn btn-sm text-capitalize"
                >
                  Back
                </button>
                <button type="submit" className="btn btn-sm btn-primary">
                  Save Preferences
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default PrivacyConsentForm;
