import { FooterContact } from './FooterContact';
import { FooterSocial } from './FooterSocial';
import { HTMLProps } from 'react';
import { Sitemap } from './Sitemap';

export const Footer = ({ className }: HTMLProps<HTMLDivElement>) => {
  return (
    <footer className="px-2 lg:px-24 mt-12 pt-9 pb-9 bg-[#00052b] text-white text-gray-500 text-base flex flex-col overflow-hidden items-center">
      <div
        className={
          'flex flex-wrap flex-col items-center sm:items-start sm:flex-row gap-4 sm:gap-4 lg:gap-6 justify-between container ' +
          className
        }
      >
        {/* Left part */}
        <div className="flex xl:flex-1 flex-col justify-center sm:justify-end items-center sm:items-start w-full sm:w-auto gap-7 ">
          <Sitemap className="flex sm:hidden pb-5" />
          <FooterContact className="text-sm sm:text-base" />
          <div className="flex">
            <h6 className="whitespace-nowrap w-full mt-6 text-center sm:text-start text-xs md:text-sm lg:text-base">
              &copy; 2022-{new Date().getFullYear()} Wearitar Corp. All rights
              reserved.
            </h6>
          </div>
        </div>

        {/* Middle part */}
        <Sitemap className="hidden sm:flex" />
        {/* Right part */}
        <div className="flex xl:flex-1 justify-center sm:justify-end items-center sm:items-start w-full sm:w-auto">
          <FooterSocial />
        </div>
      </div>
    </footer>
  );
};
