import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConsentFormContext } from '../Consent/ConsentFormContext';
import { HtmlProps } from 'react-helmet-async';

export const Sitemap = (props: HtmlProps) => {
  const { setShowConsentForm } = useContext(ConsentFormContext)!;

  return (
    <div
      className={
        'flex xl:flex-1 flex-col justify-between md:justify-start items-start w-fit whitespace-nowrap ' +
        props.className
      }
    >
      <h4 className="text-lg lg:text-xl font-medium leading-none tracking-[0.01em] ">
        Sitemap
      </h4>
      <div className="grid grid-flow-row gap-x-2 lg:gap-x-4 xl:gap-x-8 grid-cols-2 py-2 text-base">
        <Link to="/" className="hover:text-slate-500">
          Home
        </Link>
        <Link to="/docs/model-requirements" className="hover:text-slate-500">
          Documentation
        </Link>
        <Link to="https://blog.wearitar.com" className="hover:text-slate-500">
          Blog
        </Link>
        <Link to="/contact-us" className="hover:text-slate-500">
          Contact us
        </Link>
        {/* <Link to="/about" className="hover:text-slate-500">
          About us
        </Link> */}
        <Link to="/privacy" className="hover:text-slate-500">
          Privacy Policy
        </Link>
        <Link to="/terms" className="hover:text-slate-500">
          Terms of Service
        </Link>
        <button
          onClick={() => setShowConsentForm(true)}
          className="text-white bg-transparent border-none p-0 m-0 cursor-pointer hover:text-slate-500 focus:outline-none"
        >
          Manage Cookies
        </button>
      </div>
    </div>
  );
};
