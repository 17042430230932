import { useLocation } from 'react-router-dom';

export const useCanonical = () => {
  const location = useLocation();
  return (
    process.env.REACT_APP_DOMAIN_NAME_WITH_SCHEMA +
    location.pathname +
    location.search
  );
};
