import { Container } from './Container';
import { DocumentationSidebar } from './DocumentationSidebar';
import { Footer } from './Footer/Footer';
import { Navbar } from './Navbar';

export const DocumentationLayout = ({
  children,
}: {
  children: JSX.Element;
}) => {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <div className="fixed inset-x-0 z-10 w-full bg-white">
        <Navbar />
      </div>
      <Container className="flex flex-col sm:flex-row gap-3 mt-20 p-3 bg-gray-50 rounded">
        <DocumentationSidebar className="w-full sm:w-64 mb-6" />
        <div className="prose max-w-full px-10">{children}</div>
      </Container>
      <Footer />
    </div>
  );
};
