import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { getIdToken } from '../storage/idTokenStorage';

const ProtectedRoute = (props: PropsWithChildren) => {
  const idToken = getIdToken();

  if (idToken) {
    // User is authenticated, render the nested routes
    return <>{props.children}</>;
  } else {
    // User is not authenticated, redirect to the main page
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoute;
