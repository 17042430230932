import { useState, useMemo, useContext, HTMLProps } from 'react';
import { UserContext } from '../contexts/UserContext';
import { NavLink } from 'react-router-dom';

const docs = [
  {
    title: '3D model requirements',
    to: '/docs/model-requirements',
    restricted: false,
  },
  {
    title: 'Model upload and configuration',
    to: '/docs/model-configuration',
    restricted: true,
  },
  {
    title: 'Visibility and product pages',
    to: '/docs/visibility-and-product-pages',
    restricted: true,
  },
  {
    title: 'Working with presets (thumbnails)',
    to: '/docs/preset-configuration',
    restricted: true,
  },
  {
    title: 'Call to action configuration',
    to: '/docs/call-to-action-configuration',
    restricted: true,
  },
  {
    title: 'Display options',
    to: '/docs/display-options',
    restricted: true,
  },
  {
    title: 'AR configuration',
    to: '/docs/ar-configuration',
    restricted: true,
  },
  {
    title: 'Shopify integration',
    to: '/docs/shopify-integration',
    restricted: true,
  },
  {
    title: 'WiX integration',
    to: '/docs/wix-integration',
    restricted: true,
  },
  {
    title: 'WordPress integration',
    to: '/docs/wordpress-integration',
    restricted: true,
  },
];

export const DocumentationSidebar = (props: HTMLProps<HTMLDivElement>) => {
  const { userData } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const docsPerPage = 10;
  let pageNumbers = 1;

  const docsData = useMemo(() => {
    let computedDocs = docs.filter((doc) =>
      !doc.restricted || (userData?.sub && doc.restricted) ? true : false
    );

    if (searchTerm) {
      computedDocs = computedDocs.filter((doc) =>
        doc.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    pageNumbers = Math.ceil(computedDocs.length / docsPerPage);

    // Current Page slice
    return computedDocs.slice(
      (currentPage - 1) * docsPerPage,
      (currentPage - 1) * docsPerPage + docsPerPage
    );
  }, [docs, currentPage, searchTerm, userData]);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const resetFilter = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };

  return (
    <aside {...props}>
      <ul className="menu menu-sm lg:menu-md w-full gap-2 border bg-white rounded p-3">
        <li>
          <input
            type="search"
            placeholder="Search"
            className="input input-sm input-bordered hover:cursor-default"
            aria-label="Search"
            onChange={(event: any) => setSearchTerm(event.target.value)}
          />
        </li>

        {docsData.map((doc) => (
          <li key={doc.to}>
            <NavLink
              className={({ isActive }) =>
                isActive ? ' bg-primary text-white' : ''
              }
              to={doc.to}
            >
              {doc.title}
            </NavLink>
          </li>
        ))}
      </ul>

      {pageNumbers > 1 && (
        <div className="flex flex-wrap items-center py-2 gap-2">
          Page:
          {[...new Array(pageNumbers)].map((_, number) => (
            <button
              key={number}
              className="btn btn-sm"
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </button>
          ))}
        </div>
      )}
    </aside>
  );
};
