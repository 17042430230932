import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { routes, PageType } from './routes';
import { UserLayout } from './components/UserLayout';
import { DocumentationLayout } from './components/DocumentationLayout';
import { Layout } from './components/Layout';
import { Loading } from './components/Loading';
import ProtectedRoute from './navigation/ProtectedRoute';

export const Router = () => (
  <ReactRouterRoutes>
    {Object.entries(routes).map(([path, options]) => {
      // Workaround to import the named component, due to React.lazy supports only the default export
      const Component = lazy(
        async () =>
          await {
            default: await options.component(),
          }
      );
      return (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<Loading />}>
              {options.pageType === PageType.HOMEPAGE && <Component />}
              {options.pageType === PageType.RESTRICTED && (
                <ProtectedRoute>
                  <UserLayout>
                    <Component />
                  </UserLayout>
                </ProtectedRoute>
              )}
              {options.pageType === PageType.DOCUMENTATION && (
                <DocumentationLayout>
                  <Component />
                </DocumentationLayout>
              )}
              {options.pageType === PageType.GENERAL && (
                <Layout>
                  <Component />
                </Layout>
              )}
            </Suspense>
          }
        />
      );
    })}
  </ReactRouterRoutes>
);
