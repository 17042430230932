import { HTMLProps, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { SignIn } from './ActionButtons/SignIn';
import { SignUp } from './ActionButtons/SignUp';
import { List } from 'react-bootstrap-icons';
import { userMenuLinks } from './UserSidebar';
import { LogoutButton } from './LogoutButton';
import { Image } from 'react-bootstrap';

export const Navbar = ({ className }: HTMLProps<HTMLDivElement>) => {
  const toggleRef = useRef<HTMLInputElement>(null);

  const { userData } = useContext(UserContext);

  const links = [
    <a href="/">Home</a>,
    <a href="/pricing">Pricing</a>,
    <a href="/docs/model-requirements">Documentation</a>,
    <a href="https://blog.wearitar.com">Blog</a>,
  ];

  if (userData?.sub) {
    links.push(
      <Link to="/analytics" className="hidden lg:flex btn btn-primary btn-sm">
        {userData.name}
      </Link>
    );
  } else {
    links.push(<SignIn className="btn btn-sm text-capitalize" />);
    links.push(
      <SignUp id="signUp-nav" className="btn btn-sm btn-primary">
        Sign up
      </SignUp>
    );
  }

  return (
    <div
      className={
        'navbar px-2 bg-base-100 h-16 z-10 top-0 container mx-auto ' +
        (className || '')
      }
    >
      <div className="flex-1 h-full">
        <a
          href="/"
          className="relative h-full w-auto flex items-center justify-center"
        >
          <Image
            src="/assets/images/logo.svg"
            alt="Wearitar"
            className="h-4/5 w-auto"
          />
        </a>
      </div>

      <div className="drawer static w-auto">
        <input
          id="drawer-checkbox"
          type="checkbox"
          className="drawer-toggle"
          ref={toggleRef}
        />
        <div className="drawer-content flex flex-col">
          <ul className="hidden lg:flex menu menu-horizontal gap-2 px-0 items-center">
            {links.map((link, i) => (
              <li key={'linknav' + i}>{link}</li>
            ))}
          </ul>
          <div className="lg:hidden">
            <label
              htmlFor="drawer-checkbox"
              aria-label="open sidebar"
              className="btn btn-square btn-ghost"
            >
              <List width={24} height={24} />
            </label>
          </div>
        </div>
        <div className="drawer-side absolute h-screen z-50">
          <label
            htmlFor="drawer-checkbox"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>

          <ul
            className="menu p-4 w-80 min-h-full bg-base-200 absolute top-0 left-0"
            onClick={() => {
              toggleRef.current?.click();
            }}
          >
            {links.map((link, i) => (
              <li key={'link' + i}>{link}</li>
            ))}

            {userData?.sub && (
              <>
                <li className="lg:hidden" />
                {userMenuLinks.map((userMenuLink) => (
                  <li key={userMenuLink.to} className="lg:hidden">
                    <Link
                      key={userMenuLink.to}
                      to={userMenuLink.to}
                      className="lg:hidden"
                    >
                      {userMenuLink.label}
                    </Link>
                  </li>
                ))}
                <li className="lg:hidden" />
                <li className="lg:hidden">
                  <LogoutButton className="lg:hidden" />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
