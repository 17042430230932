import { useState, useEffect } from 'react';
import * as storage from '../storage/persistentTokenStorage';

function getStorageValue(key: string, defaultValue: any = '') {
  return storage.getValue(key) || defaultValue;
}

export const usePersistentState = (
  key: string,
  defaultValue: any = ''
): [any, (value: any) => void] => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    storage.setValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
