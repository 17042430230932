import { UserContext } from '../contexts/UserContext';
import { HTMLAttributes, useContext } from 'react';

export const LogoutButton = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement>) => {
  const { onLogout } = useContext(UserContext);

  return (
    <button
      className={'btn btn-sm content-center ' + className}
      {...props}
      onClick={onLogout}
    >
      {children || 'Log out'}
    </button>
  );
};
