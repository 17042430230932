import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function setValue(key: string, value: any): void {
  const savedValue =
    typeof value === 'string' || value instanceof String
      ? value
      : JSON.stringify(value);
  localStorage.setItem(key, savedValue.toString());

  cookies.set(key, value, { path: '/', domain: window.location.hostname });
}

export function getValue(key: string): object | string {
  const saved = localStorage.getItem(key) || '';
  try {
    return JSON.parse(saved);
  } catch {
    return saved;
  }
}

export function remove(key: string): void {
  localStorage.removeItem(key);
  cookies.remove(key);
}
