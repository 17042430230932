import React, { createContext, useState } from 'react';

interface ConsentFormContextProps {
  showConsentForm: boolean;
  setShowConsentForm: (value: boolean) => void;
}

export const ConsentFormContext = createContext<
  ConsentFormContextProps | undefined
>(undefined);

export const ConsentFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [showConsentForm, setShowConsentForm] = useState<boolean>(false);

  return (
    <ConsentFormContext.Provider
      value={{ showConsentForm, setShowConsentForm }}
    >
      {children}
    </ConsentFormContext.Provider>
  );
};
