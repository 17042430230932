import { UserData } from '../contexts/UserContext';
import { getRequiredUserAttributes } from './UserAttributes';
import { AccountRepository } from '../api/AccountRepository';
import { createAuthorizedClient } from '../api/http/createAuthorizedClient';
import { ProductCategory } from '../models/ProductCategory';

export const reloadUserData = async (
  previousUserData: UserData,
  setUserData: (updatedUserData: UserData) => void
) => {
  const httpClient = createAuthorizedClient();
  const repository = new AccountRepository(httpClient);
  const userDataFromServer = await repository.getUserData();
  const requiredUserAttributes = getRequiredUserAttributes(
    userDataFromServer.UserAttributes
  );

  // Find the 'custom:product_categories' attribute
  const productCategoriesAttr = userDataFromServer.UserAttributes.find(
    (userAttribute) => userAttribute.Name === 'custom:product_categories'
  );

  // Split the attribute value by commas if it exists
  const categories = productCategoriesAttr?.Value
    ? (productCategoriesAttr.Value.split(',') as ProductCategory[])
    : [];

  // Assign to requiredUserAttributes or default to all categories
  requiredUserAttributes['product_categories'] =
    categories.length > 0
      ? categories
      : (Object.values(ProductCategory) as ProductCategory[]);

  const paymentSubscription = await repository.isSubscriptionVerified();
  setUserData({
    ...previousUserData,
    ...requiredUserAttributes,
    'cognito:groups': userDataFromServer.Groups.map(
      ({ GroupName }) => GroupName
    ),
    // Implicitly, puts the user in the subscribed group if everything is ok
    is_subscription_verified: paymentSubscription.isSubscribed,
    is_payment_profile_created: paymentSubscription.isPaymentProfileCreated,
  });
};
