import { Linkedin, Twitter } from 'react-bootstrap-icons';

const social = [
  {
    icon: <Linkedin className="bg-[#00052b] text-white" />,
    href: 'https://www.linkedin.com/company/wearitar',
  },
  {
    icon: <Twitter />,
    href: 'https://twitter.com/WearItAR',
  },
];
export const FooterSocial = () => (
  <div className="flex flex-row gap-3 justify-end">
    {social.map(({ href, icon }) => (
      <a
        target="_blank"
        key={href}
        href={href}
        className="flex justify-center items-center w-8 h-8 bg-white opacity-50 rounded-full text-[#00052b] text-xl font-thin leading-relaxed tracking-tight cursor-pointer hover:opacity-100"
      >
        {icon}
      </a>
    ))}
  </div>
);
