export interface Consents {
  [key: string]: boolean;
}

const consentMapping: { [key: string]: string[] } = {
  essential: ['functionality_storage', 'security_storage'],
  basic_analytics: ['analytics_storage'],
  advanced_analytics: ['personalization_storage', 'ad_user_data'],
  marketing: ['ad_storage', 'ad_personalization'],
};

export const convertConsent = (
  updatedConsents: Consents
): { [key: string]: string } => {
  // Map category consents to individual GTM consent types
  const gtmConsents: { [key: string]: string } = {};

  Object.entries(consentMapping).forEach(([category, consentTypes]) => {
    const consentValue = updatedConsents[category] ? 'granted' : 'denied';
    consentTypes.forEach((consentType) => {
      gtmConsents[consentType] = consentValue;
    });
  });

  return gtmConsents;
};
